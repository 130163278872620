.listContainer {
    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px 0px;
    gap: 32px;

    width: 100%;
    left: 0px;
    top: 626px;

    /* bg */

    background: #F0F2F5;
}

.listSubtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* blue */

    color: #1890FF;

}

.listTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    /* identical to box height, or 100% */

    text-align: center;

    /* text-black */

    color: #333333;
}

.titleContainer {
    /* Auto layout */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}
