.postContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 88px;
}

.cardContainer {
    background: #F0F2F5;
    width: 100%;
    padding: 64px;
    height: calc(100vh - 88px);
    overflow-y: auto;
    overflow-x: hidden;
}
.cardList{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
}
