.warnPanel,.optionPanel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
    width: 208px;
    height: calc(100% - 48px);
    right: 0;
    top: 47px;
    background: #FFFFFF;
    box-shadow: 0px 4px 1px #F0F0F0;
}
.optionBody.right{
    overflow-y: auto;
    overflow-x: hidden;
}
.optionPanel {
    z-index: 200;
}
.warnPanel{
    z-index: 202;
}

.nullPic {
    width: 121px;
}

.optionFooter {
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    padding: 10px;
    width: 208px;
    height: 48px;
    box-shadow: inset 0px 1px 0px #F0F0F0;
}

.previewWindow {
    display: flex;
    width: 190px;
    height: 190px;
    background: rgba(0, 0, 0, 0);
    border-radius: 4px;
}

.optionHeadContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.descriptionBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #F5F5F5;
    border-radius: 4px;
    margin-top: 8px;
    margin-bottom: 8px;
}
.propertyItem{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}
.position-box{
    width: 100%;
}

.position-box .position-box-title{
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.25);
    margin-bottom: 8px;
}
.propertyItem_half{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}
.propertyItem_half .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.right .input-value{
    width: 100px;
    text-align: left;
}
.right .centimeter{
    margin-left: 4px;
}

.fontBoxIcon,.lockIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    width: 24px;
    height: 24px;
    border-radius: 4px;
}
.lockIcon{
    color: #999999;
}

.fontBoxIcon:hover,.lockIcon:hover{
    background-color: rgba(24, 144, 255, 0.1);
}

.fontBoxIconChecked{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    color: white;
    background-color: rgba(24, 144, 255, 1);
}
.centimeter{
    position:relative;
}
.buttonGroup{
    display: flex;
    gap: 12px;
    width: 100%;
    justify-content: right;
    padding: 24px 0 0 0;
}
.opacity-input{
    display: flex;
    align-items: center;
    height: 32px;
}
.opacity-input .units{
    margin-left: 4px;
}
.color-list{
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.optionBody.inner{
    justify-content: flex-start
}
.inner .propertyBody{
    height: auto;
    flex: none;
    overflow: visible;
}
.pos-input{
    display: flex;
    width:100%;
    flex-direction: column;
    align-items: center;
}
.pos-type{
    margin-right: 4px;
}
.plane-box{
    width: 100%
}
.plane-box .plane-box-header{
    width: 100%;
    display: flex;
    height: 32px;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}
.plane-box-header .name{
    padding-left: 10px;
}
.plane-box .plane-box-header .right{
    display: flex;
    align-items: center;
    font-size: 20px;
}
.plane-box-content{
    width: 100%;
    overflow: hidden;
    transition: height .33s linear;
}
.plane-box-content.close{
    height: 0;
}
.btn{
    transition: transform .33s linear;
}
.btn.open{
    transform: rotate(0deg);
}
.btn.close{
    transform: rotate(-90deg);
}
