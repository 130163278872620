/* app 全局样式 */
@import '~antd/dist/antd.css';

.annotation {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(51, 51, 51, 0.5);
}

.panelDivider {
    width: 90%;
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.descriptionDetail {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: rgba(51, 51, 51, 0.5);
}