.right-menu-list{
  width: 101px;
  background: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1000;
  padding: 0 4px;
  pointer-events: all;
  .menu-item{
    width: 100%;
    height: 32px;
    text-align: center;
    line-height: 32px;
    font-size: 12px;
    border-bottom: 1px solid rgba(217, 217, 217, 0.3);
    cursor: pointer;
    color: #333;
    &:last-child{
      border-bottom: none;
    }
    &:not(.disabled):hover{
      color: #2196f3;
    }
    &.disabled{
      color: rgba(0, 0, 0, 0.5);
      cursor: not-allowed;
    }
  }
}
