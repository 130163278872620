.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 4px;

    position: absolute;
    width: 100%;
    height: 88px;

    background: rgba(23, 24, 26, 0.9);
}

.tailText{
    font-style: normal;
    font-weight: 350;
    font-size: 14px;
    line-height: 21px;

    display: flex;
    align-items: center;

    color: #D9D9D9;
    opacity: 0.7;
}

.tailAText{
    font-style: normal;
    font-weight: 350;
    font-size: 14px;
    line-height: 21px;

    display: flex;
    align-items: center;

    color: #D9D9D9;
}
