.design {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.dimensionControl {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    position: absolute;
    width: 80px;
    height: 24px;
    top: 12px;
    left: calc(50% - 40px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
}

.selectedDimension {
    background: #FFFFFF;
    color: #001529;
    width: 50%;
    height: calc(100% - 1px);
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #001529;
}

.unselectedDimension {
    color: #FFFFFF;
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.blank {
    width: calc(100% - 416px);
    height: calc(100% - 48px);
    position: absolute;
    background: #F0F2F5;
    z-index: -1;
    top: 48px;
    left: 208px;
}
.renderMask{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
.maskClose{
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 40px;
    color: #fff;
    cursor: pointer;
}
