.postContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 88px;
}

.cardContainer {
    background: #F0F2F5;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    padding: 64px;
    gap: 12px;
    height: calc(100vh - 366px);
    overflow-y: auto;
    overflow-x: hidden;
}

/* 弹窗 */

.modal {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    left: 50%;
    max-width: 90%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    pointer-events: none;
    text-align: left;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
}

.modalPic {
    width: 40%;
    border-radius: 20px;
    object-fit: cover;
    margin: 0 auto;
    pointer-events: all;
}

.modalCloseIcon {
    align-items: center;
    color: #111827;
    display: flex;
    height: 80px;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 80px;
}

.modalCloseIcon:hover {
    cursor: pointer;
}

.modalCloseIcon svg {
    display: block;
}

.modalTitle {
    color: #111827;
    padding: 28px 28px 0px 28px;
    pointer-events: all;
    position: relative;
    font-size: 24px;
    font-weight: 600;
    width: calc(100% - 80px);
}

.modalContent {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-left: 40px;
    padding-right: 20px;
    pointer-events: all;
    overflow: auto;
}

.modalOverlay {
    transition: opacity 0.2s ease-out;
    pointer-events: none;
    background: rgba(15, 23, 42, 0.8);
    position: fixed;
    opacity: 0;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
}

details[open] .modalOverlay {
    pointer-events: all;
    opacity: 0.5;
    cursor: pointer;
}

details summary {
    list-style: none;
}

details summary:focus {
    outline: none;
}

details summary::-webkit-details-marker {
    display: none;
}

.postInfoContainer {
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    justify-content: left;
    align-items: center;
    padding-top: 8px;
    gap: 20px;
}

.time {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
}

.modalDetail {
    padding: 28px;
    display: flex;
    flex-direction: row;
    pointer-events: all;
}

.modalContent h1 {
    color: #1890FF;
    font-size: 14px;
    display: inline;
}
