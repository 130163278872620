.modelPanel,
.contentPanel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 12px;
    width: 208px;
    height: calc(100% - 48px);
    left: 0px;
    top: 47px;
    background: #FFFFFF;
    box-shadow: 0px 4px 1px #F0F0F0;
    padding: 0px 10px 10px 10px;
    z-index: 200;
}

.contentPanel {
    padding: 0px;
}

.modelTag {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: rgba(51, 51, 51, 0.5);
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.panelTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
}

.modelHead,
.modelStateBox,
.optionHead {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    gap: 10px;
    width: 208px;
    height: 32px;
    background: #FFFFFF;
    box-shadow: inset 0px -1px 0px #F0F0F0;
}

.visibleButton {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    /* background: url(../../assets/icon/visible.png);
    background-size: cover; */
    border: #333333;
    border-style: hidden;
    justify-content: center;
    align-items: center;
}

.visibleButton::after {
    height: 10px;
}

.buttonImg {
    display: inline-block;
    width: 24px;
    max-height: 24px;
    left: 0px;
    /* vertical-align: middle; */
}

.modelBody,
.contentBody,
.optionBody {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    overflow: hidden;
}

.propertyBody {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.propertyBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    justify-content: space-between;
    align-items: flex-start;
    gap: 6px;
    padding: 4px 10px 4px 10px;
    font-size: 12px;
}

.modelFooter {
    position: absolute;
    bottom: 10px;
}

.modelListContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow-y: scroll;
    padding-bottom: 60px;
    align-content: flex-start;
    gap: 10px;
}

.modelListContainer::-webkit-scrollbar {
    width: 0px;
}

.filterTitle {
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    display: flex;
    align-items: center;
    color: #1890FF;
}

.filterArrow {
    width: 10px;
    height: 9px;
}

.mask {
    position: absolute;
    width: 188px;
    height: 79px;
    left: 10px;
    bottom: 0px;
    background: linear-gradient(0deg, #FFFFFF 70%, rgba(255, 255, 255, 0) 100%);
}

.buttonMask {
    position: absolute;
    width: 208px;
    height: 140px;
    left: 0px;
    bottom: 45px;
    background: linear-gradient(0deg, #FFFFFF 70%, rgba(255, 255, 255, 0) 100%);
}

.filterMenu {
    border-radius: 4px;
}

.filterChoice {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
}

.contentFooterTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #1890FF;
}

.contentFooter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 30px;
    gap: 10px;
    width: 100%;
    height: 48px;
    /* light-blue */
    cursor: pointer;
    background: rgba(24, 144, 255, 0.1);
    box-shadow: inset 0px 1px 0px #F0F0F0;
}

.contentFooter:hover {
    background: rgba(24, 144, 255, 0.05);
}

.materialBox {
    padding: 10px;
}

.modelStateBox .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #333333;
    cursor: default;
    font-weight: 700;
    background-color: #FFFFFF;
}

.modelStateBox .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #F0F0F0;
}

.modelStateBox .ant-radio-button-wrapper {
    color: rgba(0, 0, 0, 0.3);
    background-color: #F9F9F9;
}

.modelStateBox .ant-radio-button-wrapper::before {
    background-color: #F9F9F9;
}

.modelStateBox .ant-radio-button-wrapper:hover {
    background-color: #F0F0F0;
}

.modelStateBox .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #333333;
    background-color: #FFFFFF;
}

.LayerPage {
    overflow-y: scroll;
    padding-bottom: 30px;
}

.LayerPage::-webkit-scrollbar {
    width: 0px;
}

.buttonText {
    width: 150px;
}

.filterIcon {
    width: 12px;
    height: 12px;
    margin: 5px;
    cursor: pointer;
    transition: all 0.5s;
}

.filterIconShow {
    width: 12px;
    height: 12px;
    margin: 5px;
    cursor: pointer;
    transform: rotate(-90deg);
    transition: all 0.5s;
}

.typeBoxHeader {
    width: 100%;
    height: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.typeBoxTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
}

.typeBoxTitleBox {
    gap: 4px;
    height: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.typeBox {
    padding: 0 0 8px 0;
    display: flex;
    flex-direction: column;
}

.typeBoxPic {
    width: 86px;
    height: 65px;
    border-radius: 4px;
    margin-bottom: 8px;
    margin-right: 8px;
}

.typeBoxPic:hover {
    border: 2px solid #1890FF;
    cursor: pointer;
}

.typeBoxBody {
    width: 188px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow-y: hidden;
    padding-top: 4px;
}
.typeBoxBody.close{
    height: 94px;
}

.typeBoxBody::-webkit-scrollbar {
    width: 0px;
}

.typeBoxContainer {
    margin-top: 12px;
    overflow-y: scroll;
    padding-bottom: 60px;
    height: calc(100vh - 300px);
}

.typeBoxContainer::-webkit-scrollbar {
    width: 0px;
}

.divideLine {
    width: 186px;
    height: 0px;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 12px;
}

.nullUpload {
    width: 72px;
    height: 59px;
}

.nullTipBox {
    width: 100%;
    padding: 0 6px 0 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.cancel-model{
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    display: flex;
    align-items: center;
    color: #1890FF;
}
