.adjustButton {
    display: "flex";
    z-Index: 1;
    position: 'absolute';
    bottom: '10vh';
    /* left: '264px'; */
    height: '33px';
    width: '37px';
    text-align: 'center';
    background-color: 'white';
    border-radius: '5px';
    font-size: 18;
    font-size: 'bold';
    cursor: 'pointer';
    justify-content: 'center';
    align-items: 'center';
}

.buttonImg {
    display: inline-block;
    width: 24px;
    max-height: 24px;
    left: 0px;
    /* vertical-align: middle; */
}