.color {
    width: 40px;
    height: 16px;
    border-radius: 2px;
}

.swatch {
    padding: 2px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
    display: inline-block;
    cursor: pointer
}

.popover {
    position: absolute;
    z-index: 2;
    left: -554px;/*164*/
}

.HueBox {
    width: 14px;
    height: 200px;
    position: relative;
    margin-left: 10px;
}

.satBox {
    width: 200px;
    height: 200px;
    position: relative;
}

.colorWindow {
    height: 100%;
    width: 100%;
    padding: 12px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.colorPanel {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.windowTitle {
    width: 100%;
    font-weight: 500;
    font-size: 16px;
}

.panelDivider {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.05);
    margin-top: 4px;
    margin-bottom: 4px;
}
.headContainer,.panelFooter{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.closeIcon{
    margin: 4px;
    cursor: pointer;
}
.operatorPanel{
    width: 300px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-left: 24px;
}
.buttonGroup{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap:8px
}
.colorRefer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: space-between;
}
.colorBlock {
    width: 40px;
    height: 14px;
}
.colorReferItem,.inputItem{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap:8px
}

.inputBox{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap:2px;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    padding: 8px 16px 8px 16px;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: rgba(0, 0, 0, 0.03);
}
.radioGroup{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.inputItem{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 120px;
    width: 150px;
}
