.headContainer {
    /* Auto layout */
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
    width: 100%;
    height: 48px;
    background: #001529;
    box-shadow: inset 0px -1px 0px #F0F0F0;
    z-index: 999;
}

.pageTitle {
    /* identical to box height */
    position: absolute;
    left: calc(50% - 16px);
    color: #FFFFFF;
}

.icon {
    width: 18px;
    height: 18px;
}

.iconForbid {
    width: 18px;
    height: 18px;
    opacity: 0.3;
}

.icon:hover {
    cursor: pointer;
}

.TagContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}

.slide1 {
    position: absolute;
    width: 28px;
    height: 4px;
    left: 143px;
    top: 43px;
    background: #1890FF;
}

.slide2 {
    position: absolute;
    width: 28px;
    height: 4px;
    left: 173px;
    top: 43px;
    background: #1890FF;
}

.logo {
    height: 16px;
    cursor: pointer;
}

.designTitle {
    color: rgba(255, 255, 255, 0.7);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(50vw - 360px);
}

.line {
    width: 26px;
    height: 0px;

    /* light-border */

    border: 0.5px solid rgba(255, 255, 255, 0.7);
    transform: rotate(90deg);

}
.header-btn{
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    &:hover{
        color: #2196f3;
    }
}
