.item {
    width: 332px;
    background: #FFFFFF;
    border-radius: 12px;
}

.item:hover {
    cursor: pointer;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.pic {
    object-fit: cover;
    width: 332px;
    height: 188px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.infoContainer {
    display: flex;
    width: 332px;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 24px 24px;
}

.title {
    font-style: normal;
    width: 284px;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.85);
}

.time {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
}

.postInfoContainer {
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;
}
