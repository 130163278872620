.edit-input {
    min-width: 1px;
    width: fit-content;
    /* overflow: auto; */
    cursor: text;
    position: relative;
}

.edit-input:empty:before {
    content: attr(placeholder);
    color: gray;
}

.edit-input:focus:before {
    content: "";
}

.edit-input:focus {
    border: 3px solid #8dacf0;
    border-radius: 4px;
    margin-left: -3px;
    margin-top: -3px;
    outline: none;
}

.edit-input-selected{
    border: 3px solid #8dacf0;
    border-radius: 4px;
    margin-left: -3px;
    margin-top: -3px;
}

[contenteditable]:focus{
	outline: none;
}

/* .edit-input:focus::after{
    edi
} */
.editable .pattern-overlay {
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}
.pattern-overlay.effectProcess {
    background-image: url("../../assets/images/overlay/demo.jpg");
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}
.text-mix-tech{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    pointer-events: none;
}
