.login-content{
  width: 100%;
  .title{
    width: 100%;
    line-height: 40px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    text-align: center;
    color: #333;
  }
  .wechat-qrcode-box{
    width: 160px;
    height: 160px;
    margin: 57px auto 14px;
  }
  .tip{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    text-align: center;
    /* dark gray */
    color: rgba(51, 51, 51, 0.75);
  }
}
.login-type{
  text-align: center;
  margin-top: 40px;
}
.form-wrapper{
  margin-top: 70px;
}
p.tip{
  color: rgba(51, 51, 51, 0.50);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.a-link{
  color: #1890ff;
}
.login-divider{
  color: rgba(0,0,0,.65);
  font-size: 14px;
  font-weight: normal;
  .ant-divider-inner-text{
    color: rgba(0,0,0,.65);
    font-size: 14px;
    font-weight: normal;
  }
}
