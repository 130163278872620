.display-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
    .canvas-wrapper{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .container-block {
        // position: absolute;
        .preview-img{
            display: none;
        }
        .layer-item-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            /*transform: translate(-50%, -50%);*/
            &.isSelected {
                .rotate-box {
                    outline: 2px solid #108ce9;
                    .rotateHandle {
                        display: block;
                    }
                }
            }
            .rotate-box {
                position: relative;
                .rotateHandle {
                    display: none;
                    width: 20px;
                    height: 20px;
                    font-size: 20px;
                    position: absolute;
                    bottom: -30px;
                    left: 50%;
                    transform: translateX(-50%);
                    cursor: grab;
                    color: #000;
                }
            }
        }
        &.canvas3D {
            width: 100%;
            height: 100%;
        }
    }
    .previewCanvas {
        position: absolute;
        background: rgba(0, 0, 0, 0.05);
        width: 190px!important;
        height: 190px!important;
        float: right;
        right: 9px;
        top: 89px;
        z-index: 200;
        flex-wrap: nowrap;
        border-radius: 4px;
        overflow: hidden;
        &.canvas2D {
            pointer-events: none;
            .preview-img{
                display:block;
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 100;
                img{
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .content {}
    }
    .model-container {
        width: 100%;
        height: 100%;
        z-index: 1;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        position: relative;
        & > svg{
            display: block;
        }
        .uvLine{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
        }
    }
    .canvas-status-container {
        position: fixed;
        bottom: 20px;
        right: 240px;
        display: flex;
        .moving-status {
            width: 37px;
            height: 33px;
            line-height: 33px;
            text-align: center;
            background: #FFFFFF;
            box-shadow: 2px 2px 7px rgba(160, 160, 160, 0.25);
            border-radius: 5px;
            color: rgba(0, 0, 0, 0.67);
            font-size: 20px;
            margin-left: 16px;
            &.rotate {
                cursor: pointer;
            }
            &.active {
                color: #fff;
                background: #2196f3;
            }
        }
        .ant-input-number-group-addon {
            padding: 0;
        }
        .input-addon-btn {
            cursor: pointer;
            padding: 0 11px;
            height: 100%;
        }
    }
}

.display-container .container-block2 {
    height: 1000px;
    width: 1200px;
    text-align: center;
}
.model-coverage-box{
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
}
.camera-mask-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: transparent;
    width: 100%;
    height: 100%;
    border-top: 100px solid rgba(0,0,0,0.9);
    border-right: 100px solid rgba(0,0,0,0.9);
    border-bottom: 100px solid rgba(0,0,0,0.9);
    border-left: 100px solid rgba(0,0,0,0.9);
    pointer-events: none;
}
