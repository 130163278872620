.container{
    position: relative;
}
.card {
    display: flex;
    padding: 7px;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    width: 208px;
    height: 32px;
    box-shadow: inset 0px -1px 0px #F0F0F0;
}


.card:hover{
    background: rgba(0, 0, 0, 0.03);
}

.typeIcon {
    color: rgba(51, 51, 51, 0.75);
    align-content: center;
    justify-content: center;
    font-size: 12px;
    z-index: 0;
}

.icon:hover{
    background-color: rgba(24, 144, 255, 0.1);
}

.icon {
    color: rgba(51, 51, 51, 0.5);
    font-size: 12px;
    padding: 4px;
    border-radius: 4px;
    z-index: 1;
}

.name {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #333333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.left{
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; 
    gap: 12px;
}

.right{
    position: absolute;
    right: 7px;
    top:4px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right; 
    gap: 2px;
}