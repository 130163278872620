.modelCardContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 86px;
    height: 104px;
}

.listPic {
    width: 86px;
    height: 82px;
    border-radius: 8px;
    object-fit: contain;
}


.unselectedName {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
    color: rgba(51, 51, 51, 0.75);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.selectedName {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
    color: #1890FF;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.tip {
    position: absolute;
    margin-top: -19px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 86px;
    height: 82px;
    border-radius: 8px;
    border: 2px solid #1890FF;
    background: rgba(0, 0, 0, 0.5);
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    color: #FFFFFF;
}

.modelCardContainer > .picHover{
    position: absolute;
    margin-top: -19px;
    width: 86px;
    height: 82px;
    border-radius: 8px;
    border: 2px solid #1890FF;
    opacity: 0;
    transition: all .1s linear;
}

.modelCardContainer:hover > .picHover{
    position: absolute;
    opacity: 1;
}
