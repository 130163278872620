.selectBox{
    width: 100%;
    margin: 0 auto;
}
.chipContainer{
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 24px;
    margin: 0 auto 20px;
}

a {
    color: #333333;
}

.selected {
    /* Auto layout */
    flex:none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 48px;
    background: rgba(24, 144, 255, 0.1);
    border-radius: 8px;
    padding: 18px;
}

.selectedText {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
}

.unselected:hover {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 48px;
    background: rgba(24, 144, 255, 0.05);
    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    padding: 18px;
}

.unselected {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 48px;
    background: #ffffff;
    border-radius: 8px;
    padding: 18px;
}
