.Container {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 10px;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.avatar {
    width: 25px;
    height: 25px;
    border-radius: 12.5px;
}

.name {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;

    /* identical to box height */

    display: flex;
    align-items: center;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.white{
    color: #FFFFFF;
}

.black{
    color: rgba(0, 0, 0, 0.45);
}
