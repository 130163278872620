.bannerContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content:center;
}

.bannerPic {
    width: 100%;
    overflow: hidden;
}

.button {
    border-radius: 100px;
    width: 248px;
    height: 64px;
    font-size: 24px;
}

.bannerTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 32px;
    color: #333333;
}

.description {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: rgba(51, 51, 51, 0.5);
    margin: 20px 0 40px 0
}

.textContainer{
    position: absolute;
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:flex-start;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);
    gap: 10px;
}
