.img-block {
    margin: 1px;
    height: 80px;
    width: 80px;
    /* width: 86px;
    height: 65px; */
    border-radius: 4px;
    margin-bottom: 8px;
    margin-right: 8px;
    border: 1px solid #eee;
}

.img-block:hover {
    border: 2px solid #1890FF;
    cursor: pointer;
}

.img-block img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: contain;
}

.hover-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80px;
    width: 80px;
    border-radius: 5px;
    margin: 2px;
    background-color: rgba(255, 255, 255, 0.6);
    position: absolute;
    top: 0;
    left: 0;
}

.hover-text .title {
    /* border-radius: 5px; */
    margin: 5px;
    font-size: 13px;
    color: rgb(5, 5, 5);
    cursor: default;
}

.hover-text .prompt {
    /* border-radius: 5px; */
    margin: 5px;
    font-size: 10px;
    /* padding-top: 20px; */
    color: gray;
    text-decoration: underline;
    cursor: default;
}


/* ============== Hover Card ============== */

.info-card {
    height: 75px;
    width: 75px;
    background-color: rgba(255, 255, 255, 0.6);
    position: absolute;
    top: 0px;
    left: 0px;
}

.info-card .hover-text {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.info-card .hover-text .title {
    /* border-radius: 5px; */
    margin: 5px;
    font-size: 14px;
    color: rgb(5, 5, 5);
}

.info-card .hover-text .prompt {
    /* border-radius: 5px; */
    margin: 5px;
    font-size: 10px;
    padding-top: 20px;
    color: gray;
    text-decoration: underline;
}
