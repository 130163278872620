.pic {
    width: 216px;
    height: 162px;
    border-radius: 12px;
}

.picItemContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    gap: 10px;
}

.picContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    gap:16px;
    flex-wrap:wrap;
}

.selectBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    padding: 64px;
}

.picItemContainer > .picHover{
    position: absolute;
    margin-top: -32px;
    width: 216px;
    height: 162px;
    border-radius: 12px;
    background: rgba(0,0,0,0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all .3s linear;
}

.picItemContainer:hover > .picHover{
    position: absolute;
    opacity: 1;
}
.picHover > img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.picHover > .link-btn{
    z-index: 2;
}
